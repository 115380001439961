import { Layout } from '@abyss/web/ui/Layout';
import { MemberSearchResultsModel } from '../constants/types';
import MemberIdCard from './MemberIdCard';
import DeviceTypeCard from './DeviceTypeCard';
import DemographicsCard from './DemographicsCard';
import MemberAdditionalDataTable from './MemberAdditionalDataTable';
import ChallengeViewTable from './ChallengeViewTable';

export interface MemberSearchResultsProps {
    data: MemberSearchResultsModel;
};

const MemberSearchResults: React.FC<MemberSearchResultsProps> = ({ data }) => {
    const { memberIds, demographics, deviceTypePairDates, additionalData, plans } = data;

    return (
        <Layout.Stack space={8} alignLayout='left' grow>
            <Layout.Group space={8} alignItems='top'>
                <DemographicsCard member={demographics}/>
                <MemberIdCard member={memberIds} />
                <DeviceTypeCard deviceTypePairDates={deviceTypePairDates} />
            </Layout.Group>
            <Layout.Group space={8} grow>
                <MemberAdditionalDataTable additionalData={additionalData} />
            </Layout.Group>
            <ChallengeViewTable plans={plans} naviId={memberIds.naviId ?? ''}/>
        </Layout.Stack>
    );
};

export default MemberSearchResults;
