import axios, { AxiosError, AxiosResponse } from 'axios';
import { ActivityEvent, Campaign, DataType, MemberCampaignFocus, MemberCampaignProgress, MemberPlan, MemberQuest, MemberQuestCalculation, MemberQuestDisplay, MemberQuestProgress, MemberQuestReward, Quest, SS_MemberQuest } from '../constants/challengeTypes';
import { MemberSearchResultsModel, SearchResponse } from '../constants/types';
import { getName } from '../helpers/challengeUtils';
import { utcToNaiveEndDate, utcToNaiveStartDate } from '../helpers/dateUtils';
import { FormData } from '../components/MemberSearchForm';
import { AMAZON_HALO, APPLE_HEALTH, FITBIT, GARMIN, NO_PARTNER } from '../constants/partners';
import { ACTIVE_MINUTES, MODERATE_ACTIVE_MINUTES, VIGOROUS_ACTIVE_MINUTES } from '../constants/activityTypes';

export interface SearchFlags {
    challengeConsoleLogging: boolean;
    filterOutIncorrectActiveMinutes: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const processSearch = async (token: string, data: FormData, toast: any, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, setData: React.Dispatch<React.SetStateAction<any>>) => {
    let success = true;
    const errorTitle = `Partial search failed to load for ID: ${data.idValue}`;
    let errorMessage = 'Some data may have inaccuracies. Please try again. For repeated failures, please report the issue under the "ISSUES" tab.';
    const requests: Promise<void>[] = [];

    toast.clean();
    setData(null);

    let responseData: SearchResponse = { Count: 0, ScannedCount: 0 } as SearchResponse;

    const requestData = `idType=${data.idType}&Search_Input=${data.idValue}&${data.dataType.filter((dt : string) => dt.indexOf("MQ") === -1).map((t : string) => `${t}=on`).join('&')}&responseVersion=v2`;

    requests.push(
        axios.post(`${process.env.REACT_APP_INVOKE_URL}/searchQueryAPI`,
            requestData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: AxiosResponse<SearchResponse>) => {
            if (response.data.Count == 0) {
                toast.clean();
                toast.show({
                    message: `No records found for ${data.idValue} under ${data.idType} `,
                    variant: 'error',
                    autoClose: false
                });
            }
            else {
                const Count = responseData.Count + response.data.Count;
                const ScannedCount = responseData.ScannedCount + response.data.ScannedCount;
                responseData = {
                    ...responseData,
                    ...response.data,
                    Count,
                    ScannedCount,
                }
            }
        })
        .catch((response: Error | AxiosError) => {
            success = false;
            if (axios.isAxiosError(response)) {
                errorMessage += ` Error: ${(response as AxiosError).message}.`;
            }
            else {
                errorMessage += ` Error: ${(response as Error).message}.`;
            }
        })
    );

    for(const dt of data.dataType.filter((dt : string) => dt.indexOf("MQ") > -1)) {
        const requestData = `idType=${data.idType}&Search_Input=${data.idValue}&${dt}=on&responseVersion=v2`;

        requests.push(
            axios.post(`${process.env.REACT_APP_INVOKE_URL}/searchQueryAPI`,
                requestData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((response: AxiosResponse<SearchResponse>) => {
                if (response.data.Count !== 0) {
                    const Count = responseData.Count + response.data.Count;
                    const ScannedCount = responseData.ScannedCount + response.data.ScannedCount;
                    responseData = {
                        ...responseData,
                        ...response.data,
                        Count,
                        ScannedCount,
                    }
                }
            })
            .catch((response: Error | AxiosError) => {
                success = false;
                if (axios.isAxiosError(response)) {
                    errorMessage += ` Error: ${(response as AxiosError).message}.`;
                }
                else {
                    errorMessage += ` Error: ${(response as Error).message}.`;
                }
            })
        );
    };

    if(data.dataType.indexOf(DataType.AE) > -1 && data.activityTypes.length > 0) {
        const requestData = `idType=${data.idType}&Search_Input=${data.idValue}&AE=on&activityType=${data.activityTypes.join(',')}&responseVersion=v2`;

        requests.push(
            axios.post(`${process.env.REACT_APP_INVOKE_URL}/searchQueryAPI`,
                requestData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((response: AxiosResponse<SearchResponse>) => {
                if (response.data.Count !== 0) {
                    const Count = responseData.Count + response.data.Count;
                    const ScannedCount = responseData.ScannedCount + response.data.ScannedCount;
                    responseData = {
                        ...responseData,
                        ...response.data,
                        Count,
                        ScannedCount,
                    }
                }
            })
            .catch((response: Error | AxiosError) => {
                success = false;
                console.log(response);
                if (axios.isAxiosError(response)) {
                    errorMessage += ` Error: ${(response as AxiosError).message}.`;
                    console.log('axios error');
                }
                else {
                    errorMessage += ` Error: ${(response as Error).message}.`;
                }
            })
        );
    };

    await Promise.all(requests)
    .then(() => {
        setData(responseData);
        setIsLoading(false);
    })
    .finally(() => {
        if(!success) {
            toast.clean();
            toast.show({
                title: errorTitle,
                message: errorMessage,
                variant: 'error',
                autoClose: false
            });
        }
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const processSearchResults = (data: SearchResponse | null | undefined, searchFlags: SearchFlags) => {
    const memberSearchResults: MemberSearchResultsModel = {
        memberIds: {
            naviId: '',
        },
        deviceTypePairDates: [],
        demographics: {},
        additionalData: {
            lastActivityReceived: {},
            lastCompletedQuest: {},
        },
        plans: [],
    };

    if(data === null || data === undefined) {
        return memberSearchResults;
    }

    return processSearchResultsSorted(data, memberSearchResults, searchFlags);
};

export const processSearchResultsSorted = (data: SearchResponse, memberSearchResults: MemberSearchResultsModel, searchFlags: SearchFlags) => {
    searchFlags.challengeConsoleLogging && console.log(data);

    if(data.MD && data.MD.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        memberSearchResults.deviceTypePairDates = data.MD.map((md: any) => ({
                dataType: md.sk.split('#')[0],
                partner: md.sk.split('#')[1],
                firstPairDate: md.devicePairDateTimes && md.devicePairDateTimes.length > 0 ? md.devicePairDateTimes[0] : '-',
                latestPairDate: md.devicePairDateTimes && md.devicePairDateTimes.length > 0 ? md.devicePairDateTimes.at(-1) : '-',
            })
        );
    }

    if(data.M && data.M.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const member = data.M.find((m: any) => m.sk === 'MemberInformation');
        if(member !== undefined) {
            memberSearchResults.memberIds.naviId = member['pk'].substring(member['pk'].indexOf('#') + 1);
            memberSearchResults.memberIds.enterpriseId = member['enterpriseId'];
            memberSearchResults.memberIds.rallyId = member['rallyId'];
            memberSearchResults.memberIds.hsidUsername = member['hsidUsername'];
            memberSearchResults.memberIds.hsidUuid = member['hsidUuid'];
            memberSearchResults.demographics.firstName = member['firstName'];
            memberSearchResults.demographics.lastName = member['lastName'];
            memberSearchResults.demographics.dob = member['birthDate'];
            memberSearchResults.demographics.registeredDate = member['registerDate'];
            memberSearchResults.demographics.gender = member['gender'];
            memberSearchResults.demographics.policyId = member['policyNumber'];
            memberSearchResults.demographics.planName = member['planName'];
            memberSearchResults.demographics.lob = member['lineOfBusiness'];
            memberSearchResults.demographics.programStartDate = member['programStartDate'];
            memberSearchResults.demographics.utcOffset = member['utcOffset'];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const mmd = data.M.find((m: any) => m.sk === 'MMD');
        if(mmd !== undefined) {
            memberSearchResults.additionalData.lastSyncDate = mmd['lastActivitySyncDateTime'];
            if(!memberSearchResults.additionalData.lastActivityReceived) memberSearchResults.additionalData.lastActivityReceived = {};
            memberSearchResults.additionalData.lastActivityReceived.date = mmd['latestActivityDataDateTime'];
            memberSearchResults.additionalData.assignedUpToDate = mmd['assignedUpToDate'];
        }
    }

    if(data.RR && data.RR.length > 0) {
        for(const rr of data.RR) {
            memberSearchResults.memberIds.hasRR = true;
            if(memberSearchResults.memberIds.hasRRSK === undefined || memberSearchResults.memberIds.hasRRSK === false) {
                memberSearchResults.memberIds.hasRRSK = rr['sk'] === 'SK';
            }
        }
    }

    if(data.ER && data.ER.length > 0) {
        memberSearchResults.memberIds.hasER = true;
    }

    memberSearchResults.plans = data.MP?.map((row: MemberPlan) => {
        const skEndDateTime = row.sk.split('#')[0];
        return ({
            ...row,
            row: row,
            skEndDateTime: skEndDateTime,
            adjustedSkEndDateTime: utcToNaiveEndDate(skEndDateTime),
            adjustedEffectiveDateTime: utcToNaiveStartDate(row.effectiveDateTime),
            campaigns: [],
        });
    }) ?? [];

    memberSearchResults.plans.map((plan) => {
        plan.campaigns = data.CD?.filter((c: Campaign) => plan.adjustedEffectiveDateTime >= c.startDateTime && plan.adjustedSkEndDateTime <= c.sk.split('#')[0]).map((c: Campaign) => ({
            ...c,
            row: c,
            skEndDateTime: c.sk.split('#')[0],
            id: c.sk.split('#')[1],
            quests: [],
        })) ?? [];
    });

    data.MCF?.map((mcf: MemberCampaignFocus) => {
        const plan = memberSearchResults.plans.find((p) => mcf.startDateTime >= p.adjustedEffectiveDateTime && mcf.startDateTime <= p.adjustedSkEndDateTime);
        if(!plan) {
            searchFlags.challengeConsoleLogging && console.log(`Progress ${mcf.pk}:${mcf.sk} with startDateTime ${mcf.startDateTime} does not belong to any plan.`);
            return;
        }
        const campaign = plan.campaigns.find((c) => c.pk === mcf.campaignDefinitionId.pk && c.sk === mcf.campaignDefinitionId.sk);
        if(!campaign) {
            searchFlags.challengeConsoleLogging && console.log(`Progress ${mcf.pk}:${mcf.sk} with startDateTime ${mcf.startDateTime} does not belong to any campaign in plan ${plan.pk}:${plan.sk}.`);
            return;
        }
        campaign.mcf = {
            ...mcf,
            row: mcf,
        };
    });

    data.MCP?.map((mcp: MemberCampaignProgress) => {
        const plan = memberSearchResults.plans.find((p) => mcp.startDateTime >= p.adjustedEffectiveDateTime && mcp.startDateTime <= p.adjustedSkEndDateTime);
        if(!plan) {
            searchFlags.challengeConsoleLogging && console.log(`Progress ${mcp.pk}:${mcp.sk} with startDateTime ${mcp.startDateTime} does not belong to any plan.`);
            return;
        }
        const campaign = plan.campaigns.find((c) => c.pk === mcp.campaignDefinitionId.pk && c.sk === mcp.campaignDefinitionId.sk);
        if(!campaign) {
            searchFlags.challengeConsoleLogging && console.log(`Progress ${mcp.pk}:${mcp.sk} with startDateTime ${mcp.startDateTime} does not belong to any campaign in plan ${plan.pk}:${plan.sk}.`);
            return;
        }
        campaign.mcp = {
            ...mcp,
            row: mcp,
        };
    });

    const topLevelMemberCampaigns = memberSearchResults.plans.flatMap((p) => p.campaigns);

    data.QD?.map((q: Quest) => {
        topLevelMemberCampaigns.filter((c) => c.questDefinitionId.pk === q.pk && c.questDefinitionId.sk === q.sk)
            .map((c) => c.qd = {
                ...q,
                row: q,
        });
    });

    const leftOverQuests: MemberQuest[] = [];

    data.MQ?.map((mq: MemberQuest) => {
        const plan = memberSearchResults.plans.find((p) => mq.startDateTime >= p.adjustedEffectiveDateTime && mq.startDateTime <= p.adjustedSkEndDateTime);
        if(!plan) {
            leftOverQuests.push(mq);
            searchFlags.challengeConsoleLogging && console.log(`Progress ${mq.pk}:${mq.sk} with startDateTime ${mq.startDateTime} does not belong to any plan.`);
            return;
        }
        const campaign = plan.campaigns.find((c) => c.questDefinitionId.pk === mq.questId.pk && c.questDefinitionId.sk === mq.questId.sk);
        if(!campaign) {
            leftOverQuests.push(mq);
            searchFlags.challengeConsoleLogging && console.log(`Progress ${mq.pk}:${mq.sk} with startDateTime ${mq.startDateTime} does not belong to any campaign in plan ${plan.pk}:${plan.sk}.`);
            return;
        }
        const activities = mq.calculationActivity === 'QuestCompletion' ? [] :
            filterActivitiesByTypeAndDateRange(data.AE, mq.calculationActivity, mq.startDateTime, mq.sk.split('#')[0], searchFlags).map((ae: ActivityEvent) => ({
                ...ae,
                row: ae,
            })) ?? [];

        campaign.quests.push({
            ...mq,
            row: mq,
            skEndDateTime: mq.sk.split('#')[0],
            quests: [],
            activities,
        });
        campaign.quests.sort((a: SS_MemberQuest, b: SS_MemberQuest) => a.startDateTime > b.startDateTime ? -1 : 1);
    });

    const topLevelMemberQuests = topLevelMemberCampaigns.flatMap((c) => c.quests);
    processChildQuests(data, topLevelMemberQuests, leftOverQuests, searchFlags);

    searchFlags.challengeConsoleLogging && console.log(leftOverQuests);
    searchFlags.challengeConsoleLogging && console.log(memberSearchResults.plans);

    const skDates = topLevelMemberQuests
        .filter((q) => q.mqp !== undefined && q.mqp.completionDateTime !== undefined && q.mqp.completionDateTime !== null)
        .sort((a, b) => (a.mqp?.completionDateTime as string) > (b.mqp?.completionDateTime as string) ? -1 : 1);
    if(skDates.length > 0) {
        memberSearchResults.additionalData.lastCompletedQuest = {
            sk: skDates[0].mqp?.sk as string,
            date: skDates[0].mqp?.completionDateTime as string,
            name: getName(skDates[0].rallyActivityId ?? ''),
        };
    }

    return memberSearchResults;
}

const processChildQuests = (data: SearchResponse, memberQuests: SS_MemberQuest[], leftOverQuests: MemberQuest[], searchFlags: SearchFlags) => {
    memberQuests.map((mq: SS_MemberQuest) => {
        const mqId = mq.sk.split('#')[1];
        const mqc = data.MQC?.find((mqc: MemberQuestCalculation) => mqId === mqc.sk.split('#')[2]);
        if(mqc !== undefined) {
            mq.mqc = {
                ...mqc,
                row: mqc,
            };
        }

        const mqd = data.MQD?.find((mqd: MemberQuestDisplay) => mqId === mqd.sk.split('#')[2]);
        if(mqd !== undefined) {
            mq.mqd = {
                ...mqd,
                row: mqd,
            };
        }

        const mqp = data.MQP?.find((mqp: MemberQuestProgress) => mqId === mqp.sk.split('#')[2]);
        if(mqp !== undefined) {
                mq.mqp = {
                ...mqp,
                row: mqp,
            };
        }

        const mqr = data.MQR?.find((mqr: MemberQuestReward) => mqId === mqr.sk);
        if(mqr !== undefined) {
            mq.mqr = {
                ...mqr,
                row: mqr,
            };
        }

        const qd = data.QD?.find((q: Quest) => mq.questId.sk === q.sk);
        if(qd !== undefined) {
            mq.qd = {
                ...qd,
                row: qd,
            };
        }

        const childQuests: MemberQuest[] = [];
        let i = 0;
        while(i < leftOverQuests.length) {
            if(leftOverQuests[i].parentMemberQuestSk !== null && leftOverQuests[i].parentMemberQuestSk?.endDateTime + '#' + leftOverQuests[i].parentMemberQuestSk?.memberQuestId === mq.sk) {
                childQuests.push(leftOverQuests[i]);
                leftOverQuests.splice(i, 1);
            } else {
                i++;
            }
        }

        mq.activities = mq.calculationActivity === 'QuestCompletion' ? [] :
            filterActivitiesByTypeAndDateRange(data.AE, mq.calculationActivity, mq.startDateTime, mq.sk.split('#')[0], searchFlags).map((ae: ActivityEvent) => ({
                ...ae,
                row: ae,
            })) ?? [];

        childQuests.map((cq: MemberQuest) => {
            mq.quests.push({
                ...cq,
                row: cq,
                skEndDateTime: cq.sk.split('#')[0],
                quests: [],
                activities: [],
            });
        });
        mq.quests.sort((a: SS_MemberQuest, b: SS_MemberQuest) => a.startDateTime > b.startDateTime ? -1 : 1);
    });

    const childrenMemberQuests = memberQuests.flatMap((q) => q.quests);
    if(childrenMemberQuests.length > 0) {
        processChildQuests(data, childrenMemberQuests, leftOverQuests, searchFlags);
    }
}

export const filterActivitiesByTypeAndDateRange = <T extends { pk: string, sk: string, partner?: string }>(activityEvents: T[], activityType: string, startDate: string, endDate: string, searchFlags: SearchFlags): T[] => {
    if(!searchFlags.filterOutIncorrectActiveMinutes) {
        return activityEvents?.filter((ae) => ae.pk.split('#')[2].indexOf(activityType) >= 0 && ae.sk >= startDate && ae.sk <= endDate) ?? [];
    }
    return activityEvents?.filter((ae) => ae.sk >= startDate && ae.sk <= endDate && ae.pk.split('#')[2].indexOf(activityType) >= 0 &&
        (
            ![VIGOROUS_ACTIVE_MINUTES, MODERATE_ACTIVE_MINUTES, ACTIVE_MINUTES].includes(activityType)
            || ([FITBIT, GARMIN, AMAZON_HALO].includes(ae?.partner ?? NO_PARTNER) && [VIGOROUS_ACTIVE_MINUTES, MODERATE_ACTIVE_MINUTES].includes(ae.pk.split('#')[2]))
            || ([APPLE_HEALTH].includes(ae?.partner ?? NO_PARTNER) && [MODERATE_ACTIVE_MINUTES].includes(ae.pk.split('#')[2]))
            || (![FITBIT, GARMIN, APPLE_HEALTH, AMAZON_HALO].includes(ae?.partner ?? NO_PARTNER) && [ACTIVE_MINUTES].includes(ae.pk.split('#')[2]))
        )
    ) ?? [];
}
