export enum GitHubProgress {
    APPROVED = 'APPROVED',
    CHANGES = 'CHANGES_REQUESTED',
    COMMENTED = 'COMMENTED',
    DISMISSED = 'DISMISSED',
    OPEN = 'OPEN'
}

export enum Progress {
    APPROVED = 'APPROVED',
    CHANGES = 'CHANGES REQUESTED',
    NEEDS_APPROVALS = 'NEEDS APPROVALS'
}

export enum Variants {
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
    INFO = 'info',
    NEUTRAL = 'neutral'
}

export const FRONTEND = ['uhc-mobile-rewards-hub', 'uhc-mobile-rewards-hub-automation', 'uhc-rewards'];

export const fullNameMap: Map<string, string> = new Map([
    ['SD', 'Saga Definition'],
    ['CD', 'Campaign Definition'],
    ['QD', 'Quest Definition'],
    ['M', 'Member'],
    ['MD', 'Member Device'],
    ['MP', 'Member Plan'],
    ['MMD', 'Member Metadata'],
    ['MQ', 'Member Quest'],
    ['MQC', 'Member Quest Calculation'],
    ['MQD', 'Member QuestDisplay'],
    ['MQP', 'Member Quest Progress'],
    ['MQR', 'Member Quest Reward'],
    ['MCF', 'Member Campaign Focus'],
    ['MCP', 'Member Campaign Progress'],
    ['MLD', 'Member Legal Details'],
    ['AE', 'Activity Event'],
    ['AR', 'Attestation Record'],
    ['RR', 'Rally Relationship'],
    ['ER', 'EnterpriseID Relationship'],
    ['HR', 'HSID Relationship'],
    ['DE', 'Device Event'],
    ['RTR', 'Revoked Token Records'],
]);

export const dataFieldMap = new Map([
    ['SD', [
        'pk',
        'sk',
        'sagaDefinitionIndexKey',
        'campaignIds',
        'prerequisiteCampaignIds',
        'campaignGuids',
        'preRequisiteCampaignGuids',
        'startDateTime'
    ]],
    ['CD', [
        'pk',
        'sk',
        'campaignType',
        'category',
        'completionType',
        'questDefinitionId',
        'questDefinitionGuid',
        'campaignDefinitionIndexKey',
        'startDateTime',
        'repeatGoal',
        'isFocusExempt',
        'focusableRequirements',
        'rallyActivityId',
        'focusDuration'
    ]],
    ['QD', [
        'pk',
        'sk',
        'source',
        'goal',
        'name',
        'description',
        'creationDateTime',
        'category',
        'displayActivity',
        'assignmentRequirements',
        'duration',
        'startDateTime',
        'questType',
        'unit',
        'trackerType',
        'calculationLambda',
        'calculationRequirements',
        'calculationActivity',
        'questDefinitionIndexKey',
        'icon',
        'rallyProductEventString',
        'rallyProductEventMap',
        'rallyActivityId',
        'actionName'
    ]],
    ['M', [
        'pk',
        'sk',
        'registerDate',
        'enterpriseId',
        'rallyId',
        'hsidUsername',
        'hsidUuid',
        'policyNumber',
        'programStartDate',
        'programEndDate',
        'zipCode',
        'state',
        'gender',
        'firstName',
        'lastName',
        'birthDate',
        'situsState',
        'lineOfBusiness',
        'customerName',
        'planName',
        'utcOffset'
    ]],
    ['MMD', [
        'pk',
        'sk',
        'lastActivitySyncDateTime',
        'latestActivityDataDateTime',
        'lastAssignmentStart',
        'lastAssignmentFinish',
        'assignedUpToDate',
        'utcOffset'
    ]],
    ['MP', [
        'pk',
        'sk',
        'planName',
        'planId',
        'planStartDateTime',
        'planEndDateTime',
        'termDateTime',
        'effectiveDateTime',
        'lastQueriedDateTime',
        'clientId',
        'createdDateTime'
    ]],
    ['VOID_MP', [
        'pk',
        'sk',
        'planName',
        'planId',
        'clientId',
        'planStartDateTime',
        'planEndDateTime',
        'termDateTime',
        'effectiveDateTime',
        'lastQueriedDateTime',
        'voidedDateTime',
        'replacedByEndDateTime',
        'replaceByMemberPlanId',
        'questsCorrected',
        'originalCreatedDateTime'
    ]],
    ['MCF', [
        'pk',
        'sk',
        'startDateTime',
        'campaignDefinitionId',
        'campaignDefinitionGuId'
    ]],
    ['MCP', [
        'pk',
        'sk',
        'progressValue',
        'progressPercentage',
        'lastCalculationDateTime',
        'completionDateTime',
        'campaignDefinitionId',
        'questDefinitionId',
        'campaignDefinitionGuid',
        'questDefinitionGuid',
        'campaignType',
        'campaignCategory',
        'completionType',
        'repeatGoal',
        'startDateTime'
    ]],
    ['MQ', [
        'pk',
        'sk',
        'startDateTime',
        'questId',
        'questDefinitionGuid',
        'questType',
        'calculationActivity',
        'rallyActivityId',
        'parentMemberQuestSk',
        'childrenMemberQuestSks'
    ]],
    ['MQC', [
        'pk',
        'sk',
        'goal',
        'calculationLamba',
        'calculationRequirements',
        'questId',
        'questDefinitionGuid',
        'questType',
        'startDateTime'
    ]],
    ['MQD', [
        'pk',
        'sk',
        'name',
        'description',
        'unit',
        'trackerType',
        'category',
        'activity',
        'icon',
        'startDateTime',
        'rallyActivityId'
    ]],
    ['MQP', [
        'pk',
        'sk',
        'progressPercent',
        'progressAmount',
        'calculationRequirements',
        'lastCalculationDateTime',
        'completionDateTime',
        'goal',
        'device',
        'rallyProductEventString',
        'rallyProductEventMap',
        'actionName'
    ]],
    ['MQR', [
        'pk',
        'sk',
        'memberDataSourceId',
        'rallyPostDateTime',
        'rallyTransactionId',
        'rallyProductEventString',
        'rallyProductEventMap',
        'questType',
        'resendPayment',
        'reportedCompletionDateTime'
    ]],
    ['RR', [
        'pk',
        'sk',
        'linkedDateTime',
        'memberId'
    ]],
    ['MLD', [
        'pk',
        'sk',
        'didAttest'
    ]],
    ['MD', [
        'pk',
        'sk',
        'devicePairDates',
        'deviceUnpairDates',
        'dataTypeStartDateTimes',
        'dataTypeEndDateTimes',
        'devicePairDateTimes',
        'deviceUnpairDateTimes',
        'lastEventTime',
        'currentState'
    ]],
    ['DE', [
        'pk',
        'sk'
    ]],
    ['AE', [
        'pk',
        'sk',
        'payloadId',
        'deviceId',
        'amount',
        'receivedDateTime',
        'partner',
        'transactionId'
    ]],
    ['GT', [
        'pk',
        'sk',
        'receivedDateTime',
        'activityEventPk',
        'activityEventSk'
    ]],
    ['AR', [
        'pk',
        'sk',
        'attestedDateTime',
        'attestationType',
        'receivedDateTime'
    ]],
    [
        'HR', ['pk', 'sk', 'hsidUuid']
    ],
    ['ER', [
        'pk',
        'sk',
        'linkedDateTime',
        'memberId'
    ]],
    ['RTR', [
        'pk',
        'sk',
        'revokedDateTime',
        'source',
    ]],
]);
