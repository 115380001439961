export const STEPS = 'Steps';
export const TIME_IN_BED = 'TimeInBed';
export const ACTIVE_MINUTES = 'ActiveMinutes';
export const VIGOROUS_ACTIVE_MINUTES = 'VigorousActiveMinutes';
export const MODERATE_ACTIVE_MINUTES = 'ModerateActiveMinutes';
export const CALORIES = 'Calories';
export const DISTANCE = 'Distance';
export const ELEVATION = 'Elevation';
export const FLOORS = 'Floors';
export const QUEST_COMPLETION = 'QuestCompletion';
export const SLEEP = 'Sleep';
export const STROKE = 'Stroke';
export const PAIR_DEVICE = 'PairDevice';

export enum ActivityType {
    STEPS = 'Steps',
    TIME_IN_BED = 'TimeInBed',
    ACTIVE_MINUTES = 'ActiveMinutes',
    VIGOROUS_ACTIVE_MINUTES = 'VigorousActiveMinutes',
    MODERATE_ACTIVE_MINUTES = 'ModerateActiveMinutes',
    CALORIES = 'Calories',
    DISTANCE = 'Distance',
    ELEVATION = 'Elevation',
    FLOORS = 'Floors',
    QUEST_COMPLETION = 'QuestCompletion',
    SLEEP = 'Sleep',
    STROKE = 'Stroke',
    PAIR_DEVICE = 'PairDevice',
}
