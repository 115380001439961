export const FITBIT = 'Fitbit';
export const GARMIN = 'Garmin';
export const MAP_MY_FITNESS = 'MapMyFitness';
export const MISFIT = 'Misfit';
export const RUN_KEEPER = 'RunKeeper';
export const STRAVA = 'Strava';
export const UA_RECORD = 'UaRecord';
export const WITHINGS = 'Withings';
export const FAT_SECRET = 'FatSecret';
export const MY_FITNESS_PAL = 'MyFitnessPal';
export const OMRON = 'Omron';
export const DEX_COM = 'DexCom';
export const RALLY = 'Rally';
export const APPLE_HEALTH = 'AppleHealth';
export const GOOGLE_FIT = 'GoogleFit';
export const SAMSUNG_HEALTH = 'SamsungHealth';
export const VALIDIC = 'Validic';
export const WELLDOC = 'Welldoc';
export const NO_PARTNER = 'NoPartner';
export const AMAZON_HALO = 'AmazonHalo';
export const OURA = 'Oura';

export enum Partner {
    FITBIT = 'Fitbit',
    GARMIN = 'Garmin',
    MAP_MY_FITNESS = 'MapMyFitness',
    MISFIT = 'Misfit',
    RUN_KEEPER = 'RunKeeper',
    STRAVA = 'Strava',
    UA_RECORD = 'UaRecord',
    WITHINGS = 'Withings',
    FAT_SECRET = 'FatSecret',
    MY_FITNESS_PAL = 'MyFitnessPal',
    OMRON = 'Omron',
    DEX_COM = 'DexCom',
    RALLY = 'Rally',
    APPLE_HEALTH = 'AppleHealth',
    GOOGLE_FIT = 'GoogleFit',
    SAMSUNG_HEALTH = 'SamsungHealth',
    VALIDIC = 'Validic',
    WELLDOC = 'Welldoc',
    NO_PARTNER = 'NoPartner',
    AMAZON_HALO = 'AmazonHalo',
    OURA = 'Oura',
}
