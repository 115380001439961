import { ChangeEvent, useCallback } from 'react';
import { useAuth } from 'react-oidc-context';
import { useToast } from '@abyss/web/hooks/useToast';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Layout } from '@abyss/web/ui/Layout';
import { Button } from '@abyss/web/ui/Button';
import { TextInput } from '@abyss/web/ui/TextInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { useForm } from '@abyss/web/hooks/useForm';
import { ActivityType } from '../constants/activityTypes';
import { DataType } from '../constants/challengeTypes';
import { MemberIdType } from '../constants/types';
import { processSearch } from '../services/search';
import { autoIdType } from '../helpers/utils';
import { TopMarginWrapper } from '../styles/styles';

export interface MemberSearchFormProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setData: (data: any) => void;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export interface FormData {
    idValue: string;
    idType: MemberIdType;
    dataType: DataType[];
    activityTypes: ActivityType[];
};

const MemberSearchForm: React.FC<MemberSearchFormProps> = ({ setData, isLoading, setIsLoading }) => {
    const auth = useAuth();
    const token = auth.user?.access_token;
    const { toast } = useToast();

    const defaultSearchValues = {
        idType: MemberIdType.RALLY_ID,
        dataType: [DataType.M, DataType.RR, DataType.ER, DataType.MMD, DataType.MD, DataType.MP, DataType.SD, DataType.CD, DataType.QD, DataType.MCF, DataType.MCP, DataType.MQ, DataType.MQD, DataType.MQC, DataType.MQP, DataType.MQR, DataType.AE],
        activityTypes: [ActivityType.STEPS, ActivityType.ACTIVE_MINUTES, ActivityType.VIGOROUS_ACTIVE_MINUTES, ActivityType.MODERATE_ACTIVE_MINUTES, ActivityType.TIME_IN_BED],
    };

    const form = useForm({
        defaultValues: defaultSearchValues,
    });

    const onSearch = useCallback(async (data : FormData, e : Event) => {
        setIsLoading(true);
        e.preventDefault();

        if(!token) {
            toast.show({
                message: 'User not authenticated',
                variant: 'error',
                autoClose: false
            });
            setIsLoading(false);
            return;
        }
        processSearch(token, data, toast, setIsLoading, setData);
    }, [token, toast, setData, setIsLoading]);

    return (
        <FormProvider state={form} onSubmit={onSearch} onError={null}>
            <Layout.Group alignItems='top'>
                    <TextInput
                        label='Member ID'
                        placeholder='Member ID'
                        model='idValue'
                        isClearable
                        onChange={(e : ChangeEvent<HTMLInputElement>) => autoIdType(e.target.value, form)}
                        validators={{ required: true }}
                        width='400px'
                    />
                    <SelectInput
                        label='ID Type'
                        model='idType'
                        validators={{ required: true }}
                        width='150px'
                        options={[
                            { label: 'EnterpriseID', value: MemberIdType.EID },
                            { label: MemberIdType.RALLY_ID, value: MemberIdType.RALLY_ID },
                            // { label: 'HSID', value: 'HSID' }, // TODO: 99% of users don't have this so its not worth existing yet until we get redshift data
                            { label: MemberIdType.NAVI_ID, value: MemberIdType.NAVI_ID },
                        ]}
                    />
            </Layout.Group>
            <TopMarginWrapper>
                <Button type='submit' isLoading={isLoading} ariaLoadingLabel='Searching' >
                    Search
                </Button>
            </TopMarginWrapper>
        </FormProvider>
    );
};

export default MemberSearchForm;
