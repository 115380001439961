import React, { FC } from 'react';
import { Modal } from '@abyss/web/ui/Modal';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { UserPreferenceContext } from '../contexts/UserPreferenceContext';

export interface UserPreferenceModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const UserPreferenceModal: FC<UserPreferenceModalProps> = ({ isOpen, setIsOpen }) => {
    const { compactDeveloperColumns, toggleCompactDeveloperColumns } = React.useContext(UserPreferenceContext);

    return (
        <Modal title='User Preferences' isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Section>
                <ToggleSwitch isChecked={compactDeveloperColumns} onChange={toggleCompactDeveloperColumns} label="Compact Developer View Columns" />
            </Modal.Section>
        </Modal>
    );
};

export default UserPreferenceModal;
