import React from 'react';
import { useAuth } from 'react-oidc-context';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';
import { useToast } from '@abyss/web/hooks/useToast';
import { Card } from '@abyss/web/ui/Card';
import { Table } from '@abyss/web/ui/Table';
import { Button } from '@abyss/web/ui/Button';
import { MemberIdModel } from '../constants/types';
import InlinePopover from './InlinePopover';
import { fixRallyRelationship } from '../services/fixes';
import CopyButton from './CopyButton';
import { Cell, HeaderCell, TopMarginBlockWrapper } from '../styles/styles';

export interface MemberIdCardProps {
    member: MemberIdModel;
};

const MemberIdCard: React.FC<MemberIdCardProps> = (props: MemberIdCardProps) => {
    const auth = useAuth();
    const token = auth.user?.access_token;
    const { toast } = useToast();
    const ldClient = useLDClient();
    const isShowFixButtonRallyRelationshipError = ldClient?.variation('release_ss_fix_rally_relationship', false) as boolean;
    const isShowEnterpriseIdRelationshipMissing = ldClient?.variation('release_ss_enterpriseid_relationship_missing', true) as boolean;
    const { enterpriseId, hasER, rallyId, hasRR, hasRRSK, hsidUsername, hsidUuid, naviId } = props.member;

    const [isFixing, setIsFixing] = React.useState(false);

    return (
        <SizeWrapper>
            <Card size='small'>
                <Table.Container title='Member ID Table'>
                    <Table.TableBody>
                        <Table.Row>
                            <HeaderCell $header>Enterprise ID</HeaderCell>
                            <Cell>
                                {enterpriseId ?? '-'}
                                <CopyButton value={enterpriseId}/>
                                {isShowEnterpriseIdRelationshipMissing &&
                                <InlinePopover
                                        title={'Missing a backend Enterprise ID Relationship record'}
                                        content={
                                            <div>
                                                <span>
                                                    Please create an incident ticket to assignment group &apos;<strong>UHC Rewards</strong>&apos; mentioning &apos;<strong>Incorrect/missing Enterprise ID Relationship record</strong>&apos; for this member.
                                                </span>
                                            </div>
                                        }
                                        displayed={hasER}
                                        icon={'warning'}
                                        width={415}
                                    />
                                }
                            </Cell>
                        </Table.Row>
                        <Table.Row>
                            <HeaderCell $header>Rally ID (RID)</HeaderCell>
                            <Cell>
                                {rallyId ?? '-'}
                                <CopyButton value={rallyId} />
                                <InlinePopover
                                        title={'This member likely cannot open Rewards on the App'}
                                        content={
                                            <div>
                                                {isShowFixButtonRallyRelationshipError ?
                                                    <span>
                                                        This member is missing an important identifier. You can correct it yourself by clicking this button
                                                        <TopMarginBlockWrapper>
                                                            <Button
                                                                size={'$sm'}
                                                                onClick={async () => fixRallyRelationship(token, naviId, toast, setIsFixing)}
                                                                isLoading={isFixing}
                                                                ariaLoadingLabel='Fixing'
                                                            >
                                                                Fix
                                                            </Button>
                                                        </TopMarginBlockWrapper>
                                                    </span>
                                                :
                                                    <span>
                                                        Please create an incident ticket to assignment group &apos;<strong>UHC Rewards</strong>&apos; mentioning &apos;<strong>Incorrect/missing Rally Relationship record</strong>&apos; for this member.
                                                    </span>
                                                }
                                            </div>
                                        }
                                        displayed={hasRR && !hasRRSK}
                                        icon={'warning'}
                                        width={!isShowFixButtonRallyRelationshipError ? 400 : undefined}
                                    />
                            </Cell>
                        </Table.Row>
                        <Table.Row>
                            <HeaderCell $header>HSID</HeaderCell>
                            <Cell>
                                {hsidUsername ?? '-'}
                                <CopyButton value={hsidUsername} />
                                <InlinePopover content={<div>UUID: {hsidUuid}<CopyButton value={hsidUuid} /></div>} displayed={hsidUuid ? true : false}/>
                            </Cell>
                        </Table.Row>
                        <Table.Row>
                            <HeaderCell $header>Navi ID (MID)</HeaderCell>
                            <Cell>
                                {naviId ?? '-'}
                                <CopyButton value={naviId} />
                            </Cell>
                        </Table.Row>
                    </Table.TableBody>
                </Table.Container>
            </Card>
        </SizeWrapper>
    );
};

const SizeWrapper = styled.div`
    min-width: 500px;
`;

export default MemberIdCard;
