import { FC, useMemo, useState } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';
import MemberSearchForm from '../components/MemberSearchForm';
import MemberSearchResults from '../components/MemberSearchResults';
import RawDataSearchForm from '../components/RawDataSearchForm';
import HotBar from '../components/HotBar';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Layout } from '@abyss/web/ui/Layout';
import { Tabs } from '@abyss/web/ui/Tabs';
import { ToastProvider } from '@abyss/web/ui/Toast';
import ActivityRecalculationCard from '../components/ActivityRecalculationCard';
import { MemberSearchResultsModel } from '../constants/types';
import { processSearchResults } from '../services/search';

const Home: FC = () => {
    const [memberSearchResultsData, setMemberSearchResultsData] = useState(null);
    const [isMemberSearchLoading, setIsMemberSearchLoading] = useState(false);

    const [activeIndex, setActiveIndex] = useState(0);

    const ldClient = useLDClient();
    const enableChallengeConsoleLogging = ldClient?.variation('enable_ss_challenge_console_logging', false) as boolean;
    const enableFilterOutIncorrectActiveMinutes = ldClient?.variation('enable_ss_filter_out_incorrect_active_minutes', true) as boolean;
    const memberSearchResults: MemberSearchResultsModel = useMemo(() =>
        processSearchResults(memberSearchResultsData, { challengeConsoleLogging: enableChallengeConsoleLogging, filterOutIncorrectActiveMinutes: enableFilterOutIncorrectActiveMinutes })
    , [memberSearchResultsData]);

    return (
        <ThemeProvider>
            <ToastProvider />
            <HotBar/>
            <PageBody fullWidth>
                <Layout.Group alignItems="top" alignLayout="left">
                    <SizeWrapper>
                        <Tabs title='Search Tabs' size='$sm'
                                active={activeIndex}
                                onTabChange={setActiveIndex}
                        >
                            <Tabs.Tab label='Member Search'>
                                <MemberSearchForm setData={setMemberSearchResultsData} isLoading={isMemberSearchLoading} setIsLoading={setIsMemberSearchLoading}/>
                            </Tabs.Tab>
                            <Tabs.Tab label='Raw Data Search'>
                                <RawDataSearchForm />
                            </Tabs.Tab>
                        </Tabs>
                    </SizeWrapper>
                    { activeIndex === 0 && memberSearchResultsData !== null && memberSearchResultsData['Count'] !== 0 &&
                        <Layout.Group space={8}>
                            <Tabs title='Member Actions' size='$sm'>
                                <Tabs.Tab label='Activity Recalculation'>
                                    <ActivityRecalculationCard naviId={memberSearchResults.memberIds.naviId ?? ''}/>
                                </Tabs.Tab>
                            </Tabs>
                        </Layout.Group>
                    }
                </Layout.Group>
                <Layout.Space />

                { activeIndex === 0 && isMemberSearchLoading ? <div>Loading...</div> :
                    activeIndex === 0 && (memberSearchResultsData === null || memberSearchResultsData['Count'] === 0) ? <div>No results found</div> :
                    activeIndex === 0 && <MemberSearchResults data={memberSearchResults}/>
                }

                { activeIndex === 1 &&
                    <div style={{ paddingBottom: '30px', paddingTop: '5px' }}>
                        <p id='changeToWhite'>Search Results</p>
                        {/* TODO: Add collapse all button */}
                        <div id='searchTableID'></div>
                    </div>
                }
            </PageBody>
        </ThemeProvider>
    );
};


const SizeWrapper = styled.main`
    width: 700px;
`;

export default Home;
